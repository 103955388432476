import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, SplitConfig, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { Button } from "@ryerson/frontend.button";
import { navigate } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { NodeLocale } from "@components/Shared/model/LanguageContent";
import { useApplication } from "@ryerson/frontend.application";

export type BelowSearchAlertProps = {
	showAlert: boolean;
	imageUrl: string;
	title: string;
	detail: string;
	buttonOneUrl: string;
	buttonOneText: string;
	buttonTwoUrl: string;
	buttonTwoText: string;
	backgroundImageUrl?: string;
};

type ContentfulNode = {
	contentful_id: string;
	company: string;
	startDate: string;
	endDate: string;
	title: string;
	details: string;
	detailImage: string;
	buttonOneLabel: string;
	buttonOneLink: string;
	buttonTwoLabel: string;
	buttonTwoLink: string;
	node_locale: NodeLocale;
	backgroundImageDesktop?: string;
	backgroundImageMobile?: string;
};

const AlertWrapper = styled.div`
	position: relative;
`;

const ButtonWrapper = styled.div`
	position: absolute;
	top: -15px;
	right: -15px;
`;

const MobileButtonWrapper = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
`;

const BelowSearchAlert: React.FC = ({}) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const [alertBelowSearchShowing, setAlertBelowSearchShowing] = React.useState(false);

	const verticalSplit: SplitConfig = {
		splitDirection: "vertical",
		splitColors: [
			{
				color: theme.colors.primary.secondaryBrand,
				percentage: "50%",
			},
			{
				color: theme.colors.primary.background,
				percentage: "50%",
			},
		],
	};

	const navigateToUrl = (url: string) => {
		navigate(url);
	};

	const allAlerts = useStaticQuery(graphql`
		query AllBelowSearchAlerts {
			allContentfulHomepageBelowSearchAlert {
				edges {
					node {
						contentful_id
						company
						startDate
						endDate
						title
						details
						detailImage {
							file {
								url
							}
						}
						buttonOneLabel
						buttonOneLink
						buttonTwoLabel
						buttonTwoLink
						backgroundImageDesktop {
							file {
								url
							}
						}
						backgroundImageMobile {
							file {
								url
							}
						}
						node_locale
					}
				}
			}
		}
	`);

	const company = process.env.GATSBY_COMPANY === "southernToolSteel" ? "STS" : "Ryerson";
	let ValidAlert = {} as ContentfulNode;

	allAlerts.allContentfulHomepageBelowSearchAlert.edges.forEach((node: any) => {
		// console.log(node);
		let currentDate = new Date();
		let timezoneDifference = currentDate.getTimezoneOffset();
		let start = new Date(node.node.startDate);
		let end = new Date(node.node.endDate);
		let startDate = new Date(start.getTime() + timezoneDifference * 60 * 1000);
		let endDate = new Date(end.getTime() + timezoneDifference * 60 * 1000);
		// console.log(node.node);
		if (
			currentDate >= startDate &&
			currentDate <= endDate &&
			node.node.node_locale.split("-").shift() === language &&
			node.node.company === company
		) {
			let hasDesktopBackground = node.node.backgroundImageDesktop ? true : false;
			let hasMobileBackground = node.node.backgroundImageMobile ? true : false;

			ValidAlert = {
				contentful_id: node.node.contentful_id,
				company: node.node.company,
				startDate: node.node.startDate,
				endDate: node.node.endDate,
				title: node.node.title,
				details: node.node.details,
				detailImage: node.node.detailImage.file.url,
				buttonOneLabel: node.node.buttonOneLabel,
				buttonOneLink: node.node.buttonOneLink,
				buttonTwoLabel: node.node.buttonTwoLabel,
				buttonTwoLink: node.node.buttonTwoLink,
				node_locale: node.node.node_locale,
				...(hasDesktopBackground && {
					backgroundImageDesktop: node.node.backgroundImageDesktop.file.url,
				}),
				...(hasMobileBackground && {
					backgroundImageMobile: node.node.backgroundImageMobile.file.url,
				}),
			};
		}
	});

	// const [alertBelowSearchClosed, setAlertBelowSearchClosed] = React.useState(
	// 	window.sessionStorage.getItem("alertBelowSearchClosed") || ""
	// );

	let alertBelowSearchClosed = "";

	if (typeof window !== "undefined") {
		alertBelowSearchClosed = window.sessionStorage.getItem("alertBelowSearchClosed") || "";
	}

	React.useEffect(() => {
		if (Object.keys(ValidAlert).length && alertBelowSearchClosed !== ValidAlert.contentful_id) {
			setAlertBelowSearchShowing(true);
		}
	}, []);

	React.useEffect(() => {
		window.sessionStorage.setItem("alertBelowSearchClosed", alertBelowSearchClosed);
	}, [alertBelowSearchClosed]);

	const closeAlert = () => {
		setAlertBelowSearchShowing(false);
		// setAlertBelowSearchClosed(ValidAlert.contentful_id);
		alertBelowSearchClosed = ValidAlert.contentful_id;
		window.sessionStorage.setItem("alertBelowSearchClosed", alertBelowSearchClosed);
	};

	return alertBelowSearchShowing ? (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="split" split={verticalSplit}>
					<AlertWrapper>
						<Flex alignContent="space-between" justifyContent="space-between">
							<FlexItem
								css={css`
									max-width: 360px;
									width: 100%;
									background-image: url(${ValidAlert.detailImage});
									background-repeat: no-repeat;
									background-position: center;
									background-size: cover;
								`}
							></FlexItem>
							<FlexItem
								grow={1}
								css={css`
									${ValidAlert.backgroundImageDesktop
										? css`
												background-image: url(${ValidAlert.backgroundImageDesktop});
												background-repeat: no-repeat;
												background-position: center;
												background-size: cover;
										  `
										: css`
												background-color: ${theme.colors.primary.darkGray};
												background-image: url(/images/homepage/alerts/belowSearchAlertBackground.jpg);
												background-blend-mode: multiply;
												background-repeat: no-repeat;
												background-position: center;
												background-size: cover;
										  `}

									min-height: 240px;
								`}
							>
								<Typography
									type="tertiary"
									color={theme.colors.primary.white}
									variant="h2"
									css={css`
										margin-top: 22px;
										margin-left: 42px;
									`}
								>
									{ValidAlert.title}
								</Typography>
								<Typography
									type="tertiary"
									color={theme.colors.primary.lightGray}
									variant="p"
									css={css`
										margin-top: 17px;
										margin-left: 42px;
									`}
								>
									{ValidAlert.details}
								</Typography>
								<Flex
									direction="row"
									justifyContent="flex-end"
									css={css`
										margin-top: 50px;
									`}
								>
									<FlexItem>
										<Button
											type="secondary"
											size="sm"
											shape="rounded"
											paddingType="relaxed"
											align="center"
											label={ValidAlert.buttonOneLabel}
											onClick={() => {
												navigateToUrl(ValidAlert.buttonOneLink);
											}}
											dataTestId={`search-alert-${ValidAlert.buttonOneLabel}-button`}
										/>
									</FlexItem>
									<FlexItem
										css={css`
											margin-right: 40px;
											margin-left: 10px;
										`}
									>
										<Button
											variant="outline"
											background={theme.colors.primary.white}
											size="sm"
											shape="rounded"
											paddingType="relaxed"
											align="center"
											label={ValidAlert.buttonTwoLabel}
											onClick={() => {
												navigateToUrl(ValidAlert.buttonTwoLink);
											}}
											dataTestId={`search-alert-${ValidAlert.buttonTwoLabel}-button`}
										/>
									</FlexItem>
								</Flex>
							</FlexItem>
						</Flex>
						<ButtonWrapper>
							<Button
								shape="circle"
								variant="solid"
								size="xs"
								foreground="light"
								background={theme.colors.primary.gray}
								label=""
								leftIcon="close"
								onClick={closeAlert}
								dataTestId="search-alert-close-button"
							/>
						</ButtonWrapper>
					</AlertWrapper>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary">
					<div
						css={css`
							${ValidAlert.backgroundImageMobile
								? css`
										background-image: url(${ValidAlert.backgroundImageMobile});
										background-repeat: no-repeat;
										background-position: center;
										background-size: cover;
								  `
								: css`
										background-color: ${theme.colors.primary.darkGray};
										background-image: url(/images/homepage/alerts/belowSearchAlertMobileBackground.jpg);
										background-blend-mode: multiply;
										background-repeat: no-repeat;
										background-position: center;
										background-size: cover;
								  `}

							min-height: 240px;
							position: relative;
						`}
					>
						<Typography
							type="tertiary"
							color={theme.colors.primary.white}
							variant="div"
							css={css`
								padding-top: 20px;
								margin-left: 24px;
								font-family: ${theme.typography.fontFamilies.headings};
								font-size: 20px;
								max-width: 195px;
							`}
						>
							{ValidAlert.title}
						</Typography>
						<Typography
							type="tertiary"
							color={theme.colors.primary.lightGray}
							variant="p"
							size="md"
							css={css`
								margin-top: 0px;
								margin-left: 24px;
								max-width: 175px;
							`}
						>
							{ValidAlert.details}
						</Typography>
						<Flex
							direction="row"
							justifyContent="space-between"
							css={css`
								margin-top: 43px;
							`}
						>
							<FlexItem
								css={css`
									margin-left: 15px;
									margin-bottom: 20px;
								`}
							>
								<Button
									type="secondary"
									size="sm"
									shape="rounded"
									paddingType="relaxed"
									align="center"
									label={ValidAlert.buttonOneLabel}
									onClick={() => {
										navigateToUrl(ValidAlert.buttonOneLink);
									}}
									dataTestId={`mobile-search-alert-${ValidAlert.buttonOneLabel}-button`}
								/>
							</FlexItem>
							<FlexItem
								css={css`
									margin-right: 15px;
									margin-bottom: 20px;
								`}
							>
								<Button
									variant="outline"
									background={theme.colors.primary.white}
									size="sm"
									shape="rounded"
									paddingType="relaxed"
									align="center"
									label={ValidAlert.buttonTwoLabel}
									onClick={() => {
										navigateToUrl(ValidAlert.buttonTwoLink);
									}}
									dataTestId={`mobile-search-alert-${ValidAlert.buttonTwoLabel}-button`}
								/>
							</FlexItem>
						</Flex>
						<MobileButtonWrapper>
							<Button
								shape="circle"
								variant="outline"
								size="xs"
								foreground="light"
								background={theme.colors.primary.white}
								label=""
								leftIcon="close"
								onClick={closeAlert}
								dataTestId="mobile-search-alert-close-button"
							/>
						</MobileButtonWrapper>
					</div>
				</ContentSection>
			</Media>
		</>
	) : (
		<></>
	);
};

export default BelowSearchAlert;
